import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapActions, mapMutations } from 'vuex';

import * as accountTypes from '@account/store/types'
import apiAccount from '@account/api'

import { LoginActionPayload } from '@account/store/actions';

// data type
declare type EumParam = {
    type: string, // type code (1,2,3 ..)
    service: string, // called by
    returnUrl: string, // to redirect (encoded value)
    userid?: string // personcode or email
}

@Component({
    name: 'RedirectView',
    methods: {
        ...mapActions({
            login: accountTypes.LOGINEX,
            getCurrent: accountTypes.CURRENT,
        }),
        ...mapMutations({
            setCurrent: accountTypes.CURRENT,
        })
    }
})
export default class RedirectView  extends Vue {

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //

    loaded: boolean = false;
    errorTitle: string = this.$t('오류').toString();
    errorMessage: string = '';
    userLang: string = '';
    returnUrl: string = '';

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: A C T I O N S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    login!: (payload: LoginActionPayload) => Promise<account.TCurrent | null>
    getCurrent!: () => Promise<account.TCurrent | null>;

    
    // ─── Mutations ───────────────────────────────────────────────────────

    setCurrent!: (payload: account.TCurrent | null) => void;
    

    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    load(){
        const { query } = this.$route;
        if(query.eumparam){
            try{
                const data : EumParam = JSON.parse(decodeURIComponent(query.eumparam.toString()));
                switch(data.type){
                    case '1': // logon -> redirect
                        console.log('RedirectView', 'data', data);
                        if(data.userid){
                            this.loginService(data.userid, data.returnUrl);
                        }
                        else{
                            throw new Error('[NOT FOUND USERID]');
                        }
                        break;
                    default:
                        throw new Error('[NOT EXIST TYPE]');
                }
            }
            catch(err: any){
                this.handleError(err, err.message);
            }
        }
        else{
            this.handleError(new Error('[NOT FOUND EUMPARAM]'), '[NOT FOUND EUMPARAM]');
        }
        
    }

    /**
     * 로그인 처리 후 returnUrl 로 이동시킵니다.
     */
    async loginService(userid: string, returnUrl: string){
        try{
            // 현재 로그인 상태 체크
            const current = await this.getCurrent();
            // 로그인된 경우
            if(current != null){
                const user = current.user;
                // 현재 client UserId 비교
                if(user.personCode.toLowerCase() == userid.toLowerCase() || user.personId.toLowerCase() == userid.toLowerCase() || user.email.toLowerCase() == userid.toLowerCase()){
                    this.goToPage(returnUrl);
                }
                // 다른 경우 인증 오류 재 로그인 안내 페이지
                else{
                    this.userLang = current.userConfig.language.split('-')[0];
                    this.returnUrl = returnUrl; 
                    this.handleError(new Error(`[CURRENT USER(${user.personId}) AND REQUEST USER(${userid}) NOT MATCH] :`));
                }
            }
        }
        catch(err){
            // 현재 로그인되지 않은 경우 getCurrent 401 error
            // 로그인되지 않은 경우 -> 로그인 페이지 이동
            this.goToPage(returnUrl);
        }
    }

    /**
     * 로그인 오류를 처리합니다.
     * @param err 
     */
    handleError(err: any, msg?: string){
        console.error(err);
        console.log(msg);
        if(msg != undefined && msg.length > 0){
            this.errorMessage = msg;
        }
        else{
            this.errorMessage = '';
        }
        
        this.loaded = true;
    }

    /**
     * 해당 URL 로 페이지를 이동시킵니다.
     * @param url 
     */
    goToPage(url: string){
        try{
            var targetUrl = decodeURIComponent(url);
            if(targetUrl.indexOf('?') > -1){
                targetUrl += '&EumRedirect=1';
            }
            else{
                targetUrl += '?EumRedirect=1';
            }
            if (targetUrl.startsWith('http://') || targetUrl.startsWith('https://')) {
                location.href = targetUrl;
            }
            else{
                location.href = location.origin + targetUrl;
            }
        }
        catch(err){
            this.handleError(err);
        }
    }

    goToLogin(){
        //logout
        apiAccount.auth.logout().then(() => {
            this.setCurrent(null);
            this.goToPage(this.returnUrl);
        }).catch((err: any) => {
            this.handleError(err, err.message);
        })
    }

    //
    // ────────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────────
    //

    created(){
        this.load();
    }
    
    
    
}